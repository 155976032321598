import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';

import 'dayjs/locale/fi';
import 'dayjs/locale/en';

import sharedFI from './locales/fi/shared.json';
import sharedEN from './locales/en/shared.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      fi: {
        shared: sharedFI,
      },
      en: {
        shared: sharedEN,
      },
    },
  });

dayjs.locale(i18n.resolvedLanguage);

export default i18n;
