import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { epportalApi } from '../services/epportalApi';

const initialState = { user: undefined };

const epportalSlice = createSlice({
  name: 'epportal',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(epportalApi.endpoints.login.matchFulfilled, (state, action) => {
      state.user = action.payload.data;
    });
    builder.addMatcher(
      isAnyOf(
        epportalApi.endpoints.logout.matchFulfilled,
        epportalApi.endpoints.logout.matchRejected,
      ),
      (state) => {
        delete state.user;
      },
    );
    builder.addMatcher(epportalApi.endpoints.verifyEmail.matchFulfilled, (state, action) => {
      state.user.contact.email.verified = true;
      state.user.org_recommendations = action.payload.data.org_recommendations;
    });
    builder.addMatcher(
      (action) => {
        return epportalApi.endpoints[action.meta?.arg?.endpointName];
      },
      (state, action) => {
        const headers = action.meta?.baseQueryMeta?.response?.headers;
        if (headers) {
          const sessionExpiresAt = headers.get('x-session-expires-at');
          if (sessionExpiresAt) {
            state.sessionExpiresAt = sessionExpiresAt / 1000;
          }
        }
      },
    );
  },
});

export default epportalSlice.reducer;
