import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Login = React.lazy(() => import('./views/pages/Login'));
const Error404 = React.lazy(() => import('./views/pages/Error404'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="*" name="Etupolku" element={<DefaultLayout />} />
      <Route exact path="/login" name="Kirjautumissivu" element={<Login />} />
      <Route exact path="/404" name="Sivua ei löydy" element={<Error404 />} />
    </>,
  ),
);

export default router;
