import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibility: true,
  unfoldable: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleVisibility: (state) => {
      state.visibility = !state.visibility;
    },
    setVisibility: (state, action) => {
      state.visibility = action.payload;
    },
    toggleUnfoldable: (state) => {
      state.unfoldable = !state.unfoldable;
    },
  },
});

export const { toggleUnfoldable, setVisibility, toggleVisibility } = sidebarSlice.actions;

export default sidebarSlice.reducer;
