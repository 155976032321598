import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';

const initialState = {
  enabled: false,
};

const darkthemeSlice = createSlice({
  name: 'darktheme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.enabled = !state.enabled;
    },
  },
});

export const darkthemeListenerMiddleware = createListenerMiddleware();

export const { toggleTheme } = darkthemeSlice.actions;

darkthemeListenerMiddleware.startListening({
  actionCreator: toggleTheme,
  effect: (action, listenerApi) =>
    localStorage.setItem(
      'dark-theme',
      JSON.stringify(JSON.stringify(listenerApi.getState().darktheme.enabled)),
    ),
});

export default darkthemeSlice.reducer;
