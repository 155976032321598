import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { setupListeners } from '@reduxjs/toolkit/query';
import sidebarReducer from './slices/sidebarSlice';
import darkthemeReducer, { darkthemeListenerMiddleware } from './slices/darkthemeSlice';
import epportalReducer from './slices/epportalSlice';
import { epportalApi } from './services/epportalApi';

const store = configureStore({
  preloadedState: load({
    states: ['darktheme', 'epportal'],
  }),
  reducer: {
    sidebar: sidebarReducer,
    darktheme: darkthemeReducer,
    [epportalApi.reducerPath]: epportalApi.reducer,
    epportal: epportalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      epportalApi.middleware,
      darkthemeListenerMiddleware.middleware,
      save({ states: ['darktheme', 'epportal'] }),
    ),
});

setupListeners(store.dispatch);

export default store;
