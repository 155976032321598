import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CSpinner } from '@coreui/react';

// eslint-disable-next-line no-unused-vars
import i18n from './shared/i18n';

import router from './router';
import store from './store';

import './scss/style.scss';

function App() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center vh-100">
            <CSpinner color="primary" />
          </div>
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
}

export default App;
